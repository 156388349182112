import React, {useEffect, useState} from 'react'
import './Plans.css'
import {GetPlansParams, Plan} from "../../types/plans"
import {getPlans} from "../../apis/getplans"
import {MerchantPlanDisplayNames} from "../../types/merchants"
import {formatCurrency} from "../../utils/currency" //, MerchantStatusDisplayNames} from "../../types/merchants"

const pageSizes = [10, 25, 50, 100, 200]

const Plans = () => {
  const [plans, setPlans] = useState<Plan[]>([])
  const [pagination, setPagination] = useState<GetPlansParams>({
    limit: pageSizes[1],
    offset: 0
  })

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await getPlans(pagination)
        const newPlans = response?.data ? response.data : []
        setPlans(newPlans)
      } catch (e) {
        alert('Failed to fetch plans')
      }
    }

    fetchPlans()
  }, [pagination])

  const handlePrevious = () => {
    setPagination((prev) => ({
      ...prev,
      offset: Math.max(0, prev.offset - prev.limit), // Prevent negative offsets
    }))
  }
  const handleNext = () => {
    setPagination((prev) => ({
      ...prev,
      offset: prev.offset + prev.limit,
    }))
  }

  const isPreviousDisabled = pagination.offset === 0
  const isNextDisabled = plans.length < pagination.limit

  return (
    <div className="table-container">
      <h1>Plans</h1>

      {/* TABLE */}
      <table>
        <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Plan Type</th>
          <th>Interval Days</th>
          <th>Price</th>
          <th>Currency</th>
          <th>Trial Days</th>
          <th>Is Test?</th>
          <th>Discount %</th>
          <th>Discount Intervals</th>
          <th>Is Active?</th>
        </tr>
        </thead>
        <tbody>
        {plans.map(plan => (
          <tr key={plan.name}>
            <td><span className="cell-text">{plan.name}</span></td>
            <td><span className="cell-text">{plan.description}</span></td>
            <td><span className="cell-text">{MerchantPlanDisplayNames[plan.type]}</span></td>
            <td><span className="cell-text">{plan.intervalDays}</span></td>
            <td><span className="cell-text">{formatCurrency(plan.price)}</span></td>
            <td><span className="cell-text">{plan.currency}</span></td>
            <td><span className="cell-text">{plan.trialDays}</span></td>
            <td><span className="cell-text">{plan.isTest ? 'Yes' : 'No'}</span></td>
            <td><span className="cell-text">{plan.discountPct? plan.discountPct + '%' : '-'}</span></td>
            <td><span className="cell-text">{plan.discountIntervals? plan.discountIntervals : '-'}</span></td>
            <td><span className="cell-text">{plan.isActive ? 'Yes' : 'No'}</span></td>
          </tr>
        ))}
        </tbody>
      </table>
      <div className="pagination-controls">
        <button onClick={handlePrevious} disabled={isPreviousDisabled}>Previous</button>
        <button onClick={handleNext} disabled={isNextDisabled}>Next</button>
      </div>
    </div>
  )
}

export default Plans
