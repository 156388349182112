import React from 'react'

const Home = () => {
  return (
    <div>
      <h1>Home</h1>
      <p>Welcome to your React app with AWS Cognito Authentication!</p>
    </div>
  )
}

export default Home
