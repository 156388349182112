import {AxiosResponse} from 'axios'
import {axiosInstance} from 'src/utils/axios'
import {ecommerceServiceURL} from "../config"
import { User } from 'src/types/users'

export type PatchUser = (user: User, shouldResendEmail: boolean) => Promise<AxiosResponse<User> | undefined>

export const patchUser: PatchUser = async (user: User, shouldResendEmail: boolean) => {
  try {
    const path = `/v2/users/${user.key}`
    const headers = {
      'Content-Type': 'application/json',
    }

    const opts = {
      baseURL: ecommerceServiceURL,
      headers: headers,
    }

    const body = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      shops: user.shops,
      shouldResendEmail: shouldResendEmail
    }

    return await axiosInstance.patch<User>(path, body, opts)
  } catch (error) {
    console.error('Error patching user:', error)
    throw error
  }
}