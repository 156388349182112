import {AxiosResponse} from 'axios'
import {axiosInstance} from 'src/utils/axios'
import {ecommerceServiceURL} from "../config"
import {GetPlansParams, Plan} from "../types/plans"

export type GetPlans = (params: GetPlansParams) => Promise<AxiosResponse<Plan[]> | undefined>

export const getPlans: GetPlans = async (params: GetPlansParams) => {
  try {
    const path = '/v2/plans'
    const headers = {
      'Content-Type': 'application/json',
    }

    const opts = {
      baseURL: ecommerceServiceURL,
      headers: headers,
      params: params
    }

    return await axiosInstance.get<Plan[]>(path, opts)
  } catch (error) {
    console.error('Error fetching plans:', error)
    throw error
  }
}