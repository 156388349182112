import {AxiosResponse} from 'axios'
import {axiosInstance} from 'src/utils/axios'
import {ecommerceServiceURL} from "../config"
import { User } from 'src/types/users'

export type PostUser = (body: User) => Promise<AxiosResponse<User> | undefined>

export const postUser: PostUser = async (body: User) => {
  try {
    const path = '/v2/users'
    const headers = {
      'Content-Type': 'application/json',
    }

    const opts = {
      baseURL: ecommerceServiceURL,
      headers: headers,
    }

    return await axiosInstance.post<User>(path, body, opts)
  } catch (error) {
    console.error('Error creating user:', error)
    throw error
  }
}