// Define enums for status, plan, and platform
export enum MerchantStatus {
  ALLOWLISTED = 'ALLOWLISTED',
  REGISTERED = 'REGISTERED',
  INSTALLED = 'INSTALLED',
  UNINSTALLED = 'UNINSTALLED',
  INACTIVE = 'INACTIVE',
}

export const MerchantStatusDisplayNames: { [key in MerchantStatus]: string } = {
  [MerchantStatus.ALLOWLISTED]: 'Allowlisted',
  [MerchantStatus.REGISTERED]: 'Registered',
  [MerchantStatus.INSTALLED]: 'Installed',
  [MerchantStatus.UNINSTALLED]: 'Uninstalled',
  [MerchantStatus.INACTIVE]: 'Inactive',
}

export enum MerchantPlan {
  NONE = 'NONE',
  BASE = 'BASE',
  ENTERPRISE = 'ENTERPRISE',
}

export const MerchantPlanDisplayNames: { [key in MerchantPlan]: string } = {
  [MerchantPlan.NONE]: 'None',
  [MerchantPlan.BASE]: 'Base',
  [MerchantPlan.ENTERPRISE]: 'Enterprise',
}

export enum MerchantPlatform {
  SHOPIFY = 'SHOPIFY',
  SALESFORCE = 'SALESFORCE_COMMERCE_CLOUD',
  FEED = 'FEED'
}

export const MerchantPlatformDisplayNames: { [key in MerchantPlatform]: string } = {
  [MerchantPlatform.SHOPIFY]: 'Shopify',
  [MerchantPlatform.SALESFORCE]: 'Salesforce',
  [MerchantPlatform.FEED]:'Feed'
}

export const MerchantPlatformDomains: { [key in MerchantPlatform]: string } = {
  [MerchantPlatform.SHOPIFY]: '.myshopify.com',
  [MerchantPlatform.SALESFORCE]: '',
  [MerchantPlatform.FEED]: ''
}

// GetMerchantsParams is a type that represents the query params to the getMerchants API
export type GetMerchantsParams = {
  limit: number,
  offset?: number

  status?: MerchantStatus
  plan?: MerchantPlan
  platform?: MerchantPlatform
}

export type NewMerchant = {
  shop: string
  status: MerchantStatus
  plan: MerchantPlan
  platform: MerchantPlatform
}

// Update the Merchant type to use these enums
export type Merchant = NewMerchant & {
  createdAt: number
  updatedAt: number
}

