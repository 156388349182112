import {MerchantPlatform} from "../types/merchants"

export const getLogoPath = (platform: MerchantPlatform): string | undefined => {
  switch (platform) {
    case MerchantPlatform.SHOPIFY:
      return '/static/images/shopify_logo_full.svg'
    default:
      return undefined
  }
}

export const editIcon = '/static/icons/edit.svg'
export const deleteIcon = '/static/icons/delete.svg'
