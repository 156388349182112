import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/index.css'
import App from './App'

import { Amplify } from 'aws-amplify'
import {awsConfig} from 'src/config'

// Configure Amplify with your AWS configuration
Amplify.configure(awsConfig)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
)
