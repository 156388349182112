import {AxiosResponse} from 'axios'
import {axiosInstance} from 'src/utils/axios'
import {User} from "../types/users"
import {ecommerceServiceURL} from "../config"

export type DeleteUser = (user: User) => Promise<AxiosResponse<object> | undefined>

export const deleteUser: DeleteUser = async (user: User) => {
  try {
    const path = '/v2/users'
    const headers = {
      'Content-Type': 'application/json',
    }

    const opts = {
      baseURL: ecommerceServiceURL,
      headers: headers,
      data: user
    }

    return await axiosInstance.delete(path, opts)
  } catch (error) {
    console.error('Error deleting user:', error)
    throw error
  }
}