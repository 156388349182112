import {AxiosResponse} from 'axios'
import {axiosInstance} from 'src/utils/axios'
import {User} from "../types/users"
import {ecommerceServiceURL} from "../config"

export type PostUserSync = () => Promise<AxiosResponse<User> | undefined>

export const postUserSync: PostUserSync = async () => {
  try {
    const path = '/v2/users/sync'
    const headers = {
      'Content-Type': 'application/json',
    }

    const opts = {
      baseURL: ecommerceServiceURL,
      headers: headers,
    }

    return await axiosInstance.post<User>(path, null, opts)
  } catch (error) {
    console.error('Error syncing user:', error)
    throw error
  }
}