import React, {useState} from 'react'
import './MerchantModal.css'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import {
  Merchant,
  MerchantPlan, MerchantPlanDisplayNames,
  MerchantPlatform,
  MerchantPlatformDisplayNames, MerchantPlatformDomains,
  MerchantStatus, MerchantStatusDisplayNames,
  NewMerchant
} from "../../types/merchants"
import {Button, FormHelperText, InputAdornment, TextField} from "@mui/material"
import {postMerchants} from "../../apis/postmerchants"
import {Nullable, Undefinable} from "../../types/utils"
import {patchMerchant} from "../../apis/patchmerchant"

interface MerchantModalProps {
  isOpen: boolean
  onClose: () => void
  merchantData: Nullable<Merchant>
}

const MerchantModal: React.FC<MerchantModalProps> = ({isOpen, onClose, merchantData}) => {
  // Check to see if the modal is in edit mode based on if merchantData is passed in
  const isEditMode = Boolean(merchantData)

  // Define the initial state of the merchant based on if the modal is in edit mode or not
  const [merchant, setMerchant] = useState<NewMerchant>(() => {
    if (isEditMode && merchantData) {
      return {
        shop: merchantData.shop,
        status: merchantData.status,
        plan: merchantData.plan,
        platform: merchantData.platform
      }
    } else {
      return {
        shop: '',
        status: MerchantStatus.ALLOWLISTED,
        plan: MerchantPlan.BASE,
        platform: MerchantPlatform.SHOPIFY,
      }
    }
  })

  if (!isOpen) return null

  // Define the available statuses to select from based on if the modal is in edit mode
  const allowedStatuses = isEditMode ? Object.values(MerchantStatus) : [MerchantStatus.ALLOWLISTED]

  // Define the available plans to select from based on if the modal is in edit mode
  const allowedPlans = isEditMode ? Object.values(MerchantPlan) : [MerchantPlan.BASE, MerchantPlan.ENTERPRISE]

  const handlePlatformChange = (event: SelectChangeEvent) => {
    setMerchant(prev => ({ ...prev, platform: event.target.value as MerchantPlatform }))
  }

  const formatShop = (shop: string, platform: MerchantPlatform) => {
    let cleanShop = shop.replace(/^(http:\/\/|https:\/\/)/, '')
    const platformDomain = MerchantPlatformDomains[platform]

    if (cleanShop.includes(platformDomain) && platformDomain !== '') {
      const domainIndex = cleanShop.indexOf(platformDomain)
      cleanShop = cleanShop.substring(0, domainIndex)
    }

    return cleanShop
  }


  const handleShopChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let shop = formatShop(event.target.value, merchant.platform)
    console.log('shop: ' + shop)

    setMerchant(prev => ({ ...prev, shop: shop }))
  }

  const handleStatusChange = (event: SelectChangeEvent) => {
    setMerchant(prev => ({ ...prev, status: event.target.value as MerchantStatus }))
  }

  const handlePlanChange = (event: SelectChangeEvent) => {
    setMerchant(prev => ({ ...prev, plan: event.target.value as MerchantPlan }))
  }

  const showAlert = (message: string) => alert(message)

  const handleApiResponse = (response: Undefinable<{ status: number }>, successCode: number) => {
    if (response && response.status === successCode) {
      onClose()
    } else {
      showAlert('There was an error. Please try again.')
    }
  }

  const handleActionClick = async () => {
    try {
      let response
      if (isEditMode) {
        response = await patchMerchant(merchant)
        handleApiResponse(response, 200)
      } else {
        const completedMerchant = {
          ...merchant,
          shop: `${merchant.shop}${MerchantPlatformDomains[merchant.platform]}`,
        }
        response = await postMerchants(completedMerchant)
        handleApiResponse(response, 201)
      }
    } catch (e) {
      let action = isEditMode ? 'updating' : 'creating'
      showAlert(`There was an error ${action} the merchant. Please try again.`)
    }
  }

  return (
    <div className="modal-container">
      <div className="modal">
        <h2 className="modal-header">{isEditMode ? 'Update Merchant' : 'Create Merchant'}</h2>
        <FormControl className="formControlsContainer">
          <InputLabel>Platform</InputLabel>
          <Select
            value={merchant.platform}
            label="Platform"
            onChange={handlePlatformChange}
            disabled={isEditMode}
          >
            {Object.values(MerchantPlatform).map((platform) => (
              <MenuItem key={platform} value={platform}>
                {MerchantPlatformDisplayNames[platform]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="formControlsContainer">
          <TextField
            value={formatShop(merchant.shop, merchant.platform)}
            onChange={handleShopChange}
            label='Shop'
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span style={{ color: 'gray' }}>{MerchantPlatformDomains[merchant.platform]}</span>
                </InputAdornment>
              ),
            }}
            disabled={isEditMode}
          />
        </FormControl>
        <FormControl className="formControlsContainer">
          <InputLabel>Status</InputLabel>
          <Select
            value={merchant.status}
            label="Status"
            onChange={handleStatusChange}
            disabled={isEditMode}
          >
            {Object.values(allowedStatuses).map((status) => (
              <MenuItem key={status} value={status}>
                {MerchantStatusDisplayNames[status]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="formControlsContainer">
          <InputLabel>Plan</InputLabel>
          <Select
            value={merchant.plan}
            label="Plan"
            onChange={handlePlanChange}
          >
            {Object.values(allowedPlans).map((plan) => (
              <MenuItem key={plan} value={plan}>
                {MerchantPlanDisplayNames[plan]}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            By selecting a plan other than "{MerchantPlanDisplayNames[MerchantPlan.NONE]}" you are granting
            access to the product without charging through the {MerchantPlatformDisplayNames[merchant.platform]} ecosystem.
          </FormHelperText>
        </FormControl>

        {/* Create and Cancel Buttons */}
        <div className="modal-actions">
          <Button className="create-merchant-button" variant="contained" onClick={handleActionClick}>
            {isEditMode ? 'Update Merchant' : 'Create Merchant'}
          </Button>
          <Button className="cancel-merchant-button" onClick={onClose}>
            Cancel
          </Button>
        </div>

      </div>
    </div>
  )
}

export default MerchantModal
