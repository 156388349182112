import React from 'react'
import '../UserModal/UserModal.css'
import { deleteUser } from 'src/apis/deleteuser'
import { User } from 'src/types/users'
import {Nullable} from "../../types/utils"
import { Button } from '@mui/material'

interface DeleteUserModalProps {
  isOpen: boolean
  onClose: () => void
  user: Nullable<User>
  refreshTable: () => void
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({ isOpen, onClose, user, refreshTable }) => {
  if (!isOpen || !user) return null
  
  const handleDeleteUser = async (user: User, onClose: () => void) => {
    // delete the user via the api.
    try {
      await deleteUser(user)
    } catch (error) {
      console.error('Error deleting user:', error)
      throw error
    }
    
    // refresh the user table so changes are reflected there.
    refreshTable()
  
    // close the modal.
    onClose()

  }

  return (
    <div className="modal-container">
      <div className="modal">
        <h2 className="modal-header">Delete User</h2>
        <p>{`Are you sure you want to delete user ${user.firstName} ${user.lastName} with email ${user.email}?`}</p>
        <Button className="create-user-button" variant="contained" onClick={() => handleDeleteUser(user, onClose)}>
          Yes
        </Button>
        <Button className="cancel-user-button" onClick={onClose}>
          No
        </Button>
      </div>
    </div>
  )
}

export default DeleteUserModal