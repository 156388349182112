import axios, { InternalAxiosRequestConfig } from 'axios'
import { fetchAuthSession } from 'aws-amplify/auth'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    try {
      const token = await getAuthToken()
      if (token) {
        config.headers.set('Authorization', token)
      }
    } catch (error) {
      console.error('Error getting auth token', error)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)


// Helper function to get the current user's token
async function getAuthToken(): Promise<string | undefined> {
  try {
    const session = await fetchAuthSession()
    return session.tokens?.accessToken?.toString()
  } catch (error) {
    console.error('Error getting current session', error)
    return undefined
  }
}

export {axiosInstance}