import React, { useState } from 'react';
import { IconButton,  Tooltip,  Typography } from '@mui/material';

interface TooltipProps {
  title: string
  tip: string
}

const SdxTooltip: React.FC<TooltipProps> = ({title, tip}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <Tooltip
      title={
        <Typography>
          {tip}
        </Typography>
      }
      open={showTooltip}
      onClose={handleMouseLeave}
      arrow
    >
      <IconButton
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
      <p>{title}</p>
      </IconButton>
    </Tooltip>
  );
};

export default SdxTooltip;
