export const region = process.env.REACT_APP_REGION
if (!region) {
  throw new Error('REACT_APP_REGION is not set')
}

export const ecommerceServiceURL = `https://${region}.ecommerce-service.simplicitydx.com`

const userPoolClientID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID as string
if (!userPoolClientID) {
  throw new Error('REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID is not set')
}

const userPoolID = process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID as string
if (!userPoolID) {
  throw new Error('REACT_APP_AWS_COGNITO_USER_POOL_ID is not set')
}

const oauthDomain = process.env.REACT_APP_AWS_COGNITO_DOMAIN as string
if (!oauthDomain) {
  throw new Error('REACT_APP_AWS_COGNITO_COGNITO_DOMAIN is not set')
}

const redirectSignIn = process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_IN as string
if (!redirectSignIn) {
  throw new Error('REACT_APP_AWS_COGNITO_REDIRECT_SIGN_IN is not set')
}

const redirectSignOut = process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_OUT as string
if (!redirectSignOut) {
  throw new Error('REACT_APP_AWS_COGNITO_REDIRECT_SIGN_OUT is not set')
}

export const awsConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: userPoolClientID,
      userPoolId:  userPoolID,
      loginWith: {
        oauth: {
          domain: oauthDomain,
          scopes: ['email', 'aws.cognito.signin.user.admin'],
          redirectSignIn: redirectSignIn.split(','),
          redirectSignOut: redirectSignOut.split(','),
          responseType: 'token' as 'code' | 'token',
        },
        username: false,
        email: true,
        phone: false,
      },
      userAttributes: {
        email_verified: {
          required: true
        },
      },
      mfa: {
        status: 'on' as 'on' | 'off' | 'optional',
      }
    }
  }
}
