import React, {useEffect, useState} from 'react'
import {getMerchants} from 'src/apis/getmerchants'
import './Merchants.css'
import {
  GetMerchantsParams,
  Merchant,
  MerchantPlanDisplayNames,
  MerchantStatus,
  MerchantStatusDisplayNames
} from "../../types/merchants"
import {formatMillisToDate} from "../../utils/time"
import {Image} from "@aws-amplify/ui-react"
import {editIcon, getLogoPath} from "../../utils/logos"
import {Button, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material"
import MerchantModal from "../../components/MerchantModal/MerchantModal"
import {Nullable} from "../../types/utils"

const pageSizes = [10, 25, 50, 100, 200]

const Merchants = () => {
  const [merchants, setMerchants] = useState<Merchant[]>([])
  const [hasMoreMerchants, setHasMoreMerchants] = useState(true)

  const [merchantsParams, setMerchantsParams] = useState<GetMerchantsParams>({
    status: MerchantStatus.INSTALLED,
    limit: pageSizes[1],
    offset: 0,
  })

  useEffect(() => {
    // Fetch merchants data when the component mounts
    const fetchMerchants = async () => {
      try {
        const response = await getMerchants(merchantsParams)
        if (response && response.data) {
          setMerchants(response.data)
          setHasMoreMerchants(response.data.length === merchantsParams.limit)
        } else {
          setHasMoreMerchants(false)
        }
      } catch (error) {
        // Handle errors
        console.error('Error fetching merchants:', error)
      }
    }

    fetchMerchants()
  }, [merchantsParams])

  const handlePrevious = () => {
    setMerchantsParams((prevParams) => ({
      ...prevParams,
      offset: Math.max(0, (prevParams.offset || 0) - prevParams.limit),
    }))
  }

  const handleNext = () => {
    setMerchantsParams((prevParams) => ({
      ...prevParams,
      offset: (prevParams.offset || 0) + prevParams.limit,
    }))
  }

  const handleStatusChange = (event: SelectChangeEvent) => {
    setMerchantsParams((prevParams) => ({
      ...prevParams,
      status: event.target.value as MerchantStatus,
      offset: 0,
    }))
  }

  const handleLimitChange = (event: SelectChangeEvent<number>) => {
    const limit = Number(event.target.value)

    setMerchantsParams((prevParams) => ({
      ...prevParams,
      limit,
      offset: 0,
    }))
  }

  enum ModalType {
    NONE = '',
    MERCHANT = 'merchant',
  }

  const [openModal, setOpenModal] = useState<ModalType>(ModalType.NONE)
  const [editingMerchant, setEditingMerchant] = useState<Nullable<Merchant>>(null)

  const handleCreateMerchantClick = () => {
    setEditingMerchant(null)
    setOpenModal(ModalType.MERCHANT)
  }

  const handleUpdateMerchantClick = (merchant: Merchant) => {
    setEditingMerchant(merchant)
    setOpenModal(ModalType.MERCHANT)
  }

  const handleCloseModal = () => {
    setOpenModal(ModalType.NONE)
  }

  return (
    <div className="table-container">
      <h1>Merchants</h1>
      <div className="controls">
        <div className="pagination-controls">
          <FormControl variant="outlined" className="form-control">
            <InputLabel htmlFor="status-select" className="form-label">
              Filter by Status:
            </InputLabel>
            <Select
              value={merchantsParams.status}
              onChange={handleStatusChange}
              displayEmpty
              inputProps={{'aria-label': 'Without label', id: 'status-select'}}
            >
              {Object.values(MerchantStatus).map((status) => (
                <MenuItem key={status} value={status}>
                  {MerchantStatusDisplayNames[status]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <InputLabel htmlFor="status-select" className="form-label">
              Select Page Size:
            </InputLabel>
            <Select
              value={merchantsParams.limit}
              onChange={handleLimitChange}
              displayEmpty
              inputProps={{'aria-label': 'Without label', id: 'status-select'}}
            >
              {pageSizes.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button onClick={handleCreateMerchantClick} className="create-button" variant='contained'>Create
          Merchant</Button>
      </div>

      {/* Create Merchant Modal */}
      {openModal === ModalType.MERCHANT && (
        <MerchantModal
          isOpen={true}
          onClose={handleCloseModal}
          merchantData={editingMerchant}
        />
      )}

      {/* Modal Overlay */}
      {openModal && (
        <div className="overlay" onClick={handleCloseModal}></div>
      )}


      <table>
        <thead>
        <tr>
          <th>Shop</th>
          <th>Status</th>
          <th>Plan Type</th>
          <th>Platform</th>
          <th>Created At</th>
          <th>Updated At</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {merchants.map(merchant => (
          <tr key={merchant.shop}>
            <td>
              <span className="cell-text"><a href={`https://${merchant.shop}`} target="_blank"
                                             rel="noopener noreferrer">{merchant.shop}</a></span>
            </td>
            <td><span className="cell-text">{MerchantStatusDisplayNames[merchant.status]}</span></td>
            <td>
              <span className="cell-text">{MerchantPlanDisplayNames[merchant.plan]}</span>
            </td>
            <td>
              <Image
                alt={merchant.platform + ' Logo'}
                src={getLogoPath(merchant.platform)}
                className='platform-logo'
              />
            </td>
            <td><span className="cell-text">{formatMillisToDate(merchant.createdAt)}</span></td>
            <td><span className="cell-text">{formatMillisToDate(merchant.updatedAt)}</span></td>
            <td>
              <IconButton aria-label="edit" onClick={() => {
                handleUpdateMerchantClick(merchant)
              }} className='icon-button'>
                <Image
                  alt={'Edit Icon'}
                  src={editIcon}
                />
              </IconButton>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      <div className="pagination-controls">
        <button onClick={handlePrevious} disabled={merchantsParams.offset === 0}>
          Previous
        </button>
        <button onClick={handleNext}
                disabled={!hasMoreMerchants}>
          Next
        </button>
      </div>
    </div>
  )
}

export default Merchants
