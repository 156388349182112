import React, {useEffect, useState} from 'react'
import './UserModal.css'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import {getMerchants} from 'src/apis/getmerchants'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import {
  GetMerchantsParams,
 MerchantStatus,
} from "../../types/merchants"
import {Button, Checkbox, FormControlLabel,  TextField} from "@mui/material"
import {Nullable, Undefinable} from "../../types/utils"
import { User } from 'src/types/users'
import { postUser } from 'src/apis/postuser'
import { patchUser } from 'src/apis/patchuser'
import SdxTooltip from '../SdxTooltip/SdxTooltip'

interface UserModalProps {
  isOpen: boolean
  onClose: () => void
  userData: Nullable<User>
  refreshTable: () => void
}

const UserModal: React.FC<UserModalProps> = ({isOpen, onClose, userData, refreshTable}) => {
  // Check to see if the modal is in edit mode based on if userData is passed in
  const isEditMode = Boolean(userData)
  const [shouldResendInvite, setShouldResendInvite] = useState<boolean>(false)
  const [validationError, setValidationError] = useState<string>('')
  const [availableShops, setAvailableShops] = useState<string[]>([]) 
  const [selectedShops, setSelectedShops] = useState<string[]>(() => {
    if (isEditMode && userData?.shops) {
      return userData.shops
    } else {
      return []
    }
  })

  // Define the initial state of the user based on if the modal is in edit mode or not
  const [user, setUser] = useState<User>(() => {
    if (isEditMode && userData) {
      return userData
    } else {
      let obj = {
        key:'',
        email: '',
        firstName: '',
        lastName: '',
        shops: [],
      } 
      return obj as User
    }
  })

  // get the initial list of available shops via use effect.
  useEffect(() => {
    const fetchAvailableShops = async () => {
      try {
        // fetch the merchants from the API.
        // TODO: will need to adjust the pagination of this.
        let params = {
          status: MerchantStatus.INSTALLED,
          limit: 100,
          offset: 0,
        } as GetMerchantsParams
        const response = await getMerchants(params);
        // use the response to build an array of merchant names.
        if (response) {
          setAvailableShops(response.data.map((merchant) => merchant.shop));
        } else {
          throw Error('no merchants found.')
        }
      } catch (error) {
        console.error('Error fetching available shops:', error);
      }
    };

    fetchAvailableShops();
  }, []);

  if (!isOpen) return null

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let firstName = event.target.value

    setUser(prev => ({ ...prev, firstName: firstName }))
  }

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let lastName = event.target.value

    setUser(prev => ({ ...prev, lastName: lastName }))
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let email = event.target.value

    setUser(prev => ({ ...prev, email: email }))
  }

  const showAlert = (message: string) => alert(message)

  const handleApiResponse = (response: Undefinable<{ status: number }>, successCode: number) => {
    if (response && response.status === successCode) {
      refreshTable()
      onClose()
    } else {
      showAlert('There was an error. Please try again.')
    }
  }

  const validEmail = (email: string | undefined): boolean =>  {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (! email || !emailRegex.test(email)) {
      setValidationError('Please enter a valid email address.');
      return false
    } else {
      setValidationError('')
      return true
    }
  }

  const handleSelectedShopsChange= (event: SelectChangeEvent<typeof selectedShops>) => {
    let value = event.target.value
    setSelectedShops(
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShouldResendInvite(event.target.checked)
  }

  const handleActionClick = async () => {
    // ensure we have a properly formatted email before we call the apis.
    if (!validEmail(user.email)) {
      return
    }

    // attempt to call the API.
    try {
      const finishedUser = {
        ...user,
        shops: selectedShops
      }
      let response
      if (isEditMode) {
        response = await patchUser(finishedUser, shouldResendInvite)
        handleApiResponse(response, 200)
      } else {
        response = await postUser(finishedUser)
        handleApiResponse(response, 201)
      }
    } catch (e) {
      let action = isEditMode ? 'updating' : 'creating'
      showAlert(`There was an error ${action} the user. Please try again.`)
    }
  }

  return (
    <div className="modal-container">
      <div className="modal">
        <h2 className="modal-header">{isEditMode ? 'Update User' : 'Create User'}</h2>
        <FormControl className="formControlsContainer">
          <TextField
            onChange={handleFirstNameChange}
            label='First Name'
            variant="outlined"
            value={user.firstName}
          />
        </FormControl>
        <FormControl className="formControlsContainer">
          <TextField
            onChange={handleLastNameChange}
            label='Last Name'
            variant="outlined"
            value={user.lastName}
          />
        </FormControl>
        { !isEditMode && (
        <FormControl className="formControlsContainer">
          <TextField
            onChange={handleEmailChange}
            label='Email'
            variant="outlined"
            value={user.email}
          />
        </FormControl>
        )}
        { isEditMode && (
          <SdxTooltip
            title={`Where's Email?`}
            tip={` You cannot update the email addres for an existing user. If you've created a user with a typo in their email, please delete the incorrect user and create a new one with the right email.`}
          />
        )}
        <FormControl className="formControlsContainer">
          <InputLabel>{'Shop (multi select)'}</InputLabel>
          <Select
            multiple
            value={selectedShops}
            onChange={handleSelectedShopsChange}
          >
          {availableShops.map((shop) => (
            <MenuItem
              key={shop}
              value={shop}
            >{shop}
            </MenuItem>
          ))}
          </Select>
        </FormControl>
        {/* conditional checkbox for resending the email invite */}
        {isEditMode &&
          <FormControl>
            <FormControlLabel 
              control={
                <Checkbox
                  checked={shouldResendInvite}
                  onChange={handleCheckboxClick}
                />
              } 
              label="Should resend invite email?" />
          </FormControl>
        }
        {validationError && <span style={{color: 'red'}}>{validationError}</span>}
        {/* Create and Cancel Buttons */}
        <div className="modal-actions">
          <Button className="create-user-button" variant="contained" onClick={handleActionClick}>
            {isEditMode ? 'Update User' : 'Create User'}
          </Button>
          <Button className="cancel-user-button" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UserModal
